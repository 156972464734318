.container {
    align-items: center;
    font-weight: 700;
    color: inherit;
    position: relative;

    .title {
        opacity: 0;
        top: calc(100% + 10px);
        position: absolute;
        background-color: #fff;
        border-radius: 20px;
        padding: 5px 10px;
        box-shadow: var(--shadow);
        right: 50%;
        transform: translateX(50%);
        color: inherit;
        white-space: nowrap;
    }
    .icons {
        font-size: 1.2rem;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        
        span {
            color: var(--color-main);
            font-size: 0.7em;
        }
    }
    &:hover, &.active {
        box-shadow: inset 0 2px 5px rgba(0,0,0,0.3);
        .icons {
            color: #2274fa;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        .title {
            display: none;
        }
        .icons {
            
            span {
                display: none;
            }
        }
    }
    
}