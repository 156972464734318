.container {
    align-items: center;
    font-weight: 700;
    color: inherit;
    position: relative;

    .title {
        opacity: 0;
        top: calc(100% + 15px);
        position: absolute;
        background-color: #fff;
        border-radius: 20px;
        padding: 5px 10px;
        box-shadow: var(--shadow);
        right: 50%;
        transform: translateX(50%);
        color: inherit;
        flex-direction: row;
        gap: 3px;
        font-size: 0.8rem;
    }
  
    .stars {
        flex-direction: row;
        font-size: 1.2rem;
        color: #ddd;
        transition: color 0.3s;
        gap: 3px;

        &>span {
            position: relative;
            color: #0FB7FE;
            align-items: center;
            justify-content: center;

            &.active {
                color: #0FB7FE;
            }
            &.hover {
                color: #2274fa;
            }
        }
    }

    &:hover {
        .title {
            opacity: 1;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .title {
            background-color: none;
        }
    }
}
@media screen and (min-width: 769px) {
    .container {
        
    }
}