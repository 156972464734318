.container {
    border-radius: 50%;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(#049AFF, #25E3FE);
    border: solid 2px #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    color: #fff;

    &.lg {
        width: 70px;
        font-size: 2.5rem;
    }
    &.sm {
        width: 45px;
        font-size: 1.2rem;
    }

    &:hover {
        box-shadow: 0 2px 5px rgba(0,0,0,0.3), inset 0 2px 5px rgba(0,0,0,0.3);
    }

    audio {
        display: none;
    }

    svg {
        margin-left: 2px;
        &.active {
            margin-left: 0;
            animation: playing 1s infinite alternate;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        
    }
}
@media screen and (min-width: 769px) {
    .container {
        
    }
}

@keyframes playing {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}