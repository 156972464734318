.container {
    section {
        &>div {
            flex-direction: row;
            align-items: center;
            gap: 10px;
            
            h1 {
                font-size: 1rem;
                font-weight: 500;
            }
        }
    
        .left {
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }
    }
    
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        box-shadow: var(--shadow);
        width: 100%;
        section {
            a {
                text-align: center;
                .logo {
                    width: 60%;
                }
            }
        }
    }
}
@media screen and (min-width: 769px) {
    .container {
        section {
            flex-direction: row;
            justify-content: space-between;
            .logo {
                width: 50px;
            }
        }
    }
}