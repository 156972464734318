.container {
    width: 100%;
    gap: 10px;
    margin-top: 40px;
    background-image: linear-gradient(#049AFF, #058de7);
    border-top: solid 3px #fff;
    border-bottom: solid 3px #fff;
    box-shadow: var(--shadow);
    color: #fff;
    overflow: hidden;

    img {
        z-index: 1;
    }

    section {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        &>div {
            width: 40%;
            z-index: 1;
        }

        .right {
            gap: 20px;
            padding: 50px 0 60px;
            justify-content: center;
    
            .icon {
                flex-direction: row;
                align-items: center;
                gap: 5px;
                img {
                    width: 30px;
                    border-radius: 5px;
                }
            }
            .title {
                gap: 20px;
    
                h4 {
                    font-size: 2.5rem;
                }
                h5 {
                    font-size: 1.5rem;
                }
            }
            .links {
                flex-direction: row;
                gap: 20px;
                
                a {
                    width: 40%;
                }
    
            }
        }
        
        .left {
            position: relative;
            align-items: center;
            justify-content: center;

            .circles {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                align-items: center;
                justify-content: center;
                max-width: unset;

                div {
                    position: absolute;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    border: solid 1px rgba(255, 255, 255, 0.3);
                    animation: circle 3s infinite;

                    &:nth-of-type(1) {
                        width: 200%;
                    }
                    &:nth-of-type(2) {
                        width: 160%;
                        animation-delay: 0.5s;
                    }
                    &:nth-of-type(3) {
                        width: 120%;
                        animation-delay: 1s;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .container {
        section {
            flex-direction: column-reverse;
            gap: var(--gap);
            padding: var(--gap);

            &>div {
                width: 100%;
            }
            .right {
                padding: 20px 0;
                .title {
                    h4 {
                        font-size: 2rem;
                    }
                    h5 {
                        font-size: 1.2rem;
                    }
                }
                .links {
                    a {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@keyframes circle {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(0.8);
    }
}