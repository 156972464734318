.container {
    border-radius: 40px;
    border: solid 7px #fff;
    box-shadow: var(--shadow);
    padding: 20px;
    gap: 20px;

    h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .answers {
        gap: 10px;
        flex-direction: row;

        &>div {
            flex: 1;
            font-size: 0.8em;
            padding: 10px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            background-color: #fff;

            div {
                flex-direction: row;
                align-items: center;
                gap: 10px;
                z-index: 1;
            }
            i {
                
                z-index: 1;
            }
            span {
                z-index: 0;
                position: absolute;
                right: 0;
                bottom: 0;
                height: 5px;
                border-radius: 10px;
                background-color: var(--color-main);
            }

            &:hover {
                background-color: rgba(35, 115, 250, 0.2);
            }
            &.active {
                background-color: rgba(35, 115, 250, 0.8);
                color: #fff;
            }
        }
    }
    .buttons {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        a {
            color: var(--color-tint);
            text-decoration: underline;
        }
        .button {
            flex-direction: row;
            align-items: center;
            gap: 5px;
            padding: 6px 30px;
            font-size: 0.9em;
            font-weight: 700;
            background-color: rgba(35, 115, 250, 0.8);
            color: #fff;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        border-radius: 0;
        border: none;
        background-color: #fff;

        .answers {
            flex-direction: column;

            &>div {
                font-size: 1em;
                background-color: var(--bg-main);

                i {
                    font-size: 0.8em;
                }
            }
        }
        .buttons {
            flex-direction: column;
            gap: 20px;
        }
    }
}