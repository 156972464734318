.container {
    gap: 20px;

    .stationsList {
        display: flex;
        flex-direction: column;
        gap: 15px;
        flex: 1;

        &>div {
            gap: 20px;
            
            .station {
                flex-direction: row;
                gap: 10px;
                align-items: center;
                padding: 10px;
                justify-content: space-between;
                background: var(--bg-main);
                border-radius: 5px;
                border: var(--border);
                border-color: transparent;
                cursor: grab;
    
                .name {
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;
                    flex: 1;
    
                    h3 {
                        font-size: 1.1rem;
                        margin: 0;
                        font-weight: 400;
                    }
    
                    span {
                        font-weight: 700;
                        font-size: 1.2rem;
                    }

                }
                .logo {
                    width: 50px;
                    border-radius: 5px;
                    overflow: hidden;
                }
            }
            .stationEdit {
                display: none;
                gap: 20px;

                &>div {
                    flex-direction: row;
                    gap: 20px;
        
                    .input {
                        gap: 5px;
                        font-weight: 700;
                        font-size: 0.8rem;
        
                        input {
                            padding: 5px;
                            font-weight: 300;
                            border: var(--border);
                            border-radius: 5px;
                            color: var(--text-main);
                            width: 100%;
                            text-align: center;
                        }
        
                        &.full {
                            flex: 1;
                        }
                    }
                }
                &.active {
                    display: flex;
                }
            }
        }
    }
}