.container {
    background: #fff;
    position: relative;
    align-items: center;
    justify-content: center;
    color: #757575;
    border: solid 1px #ddd;
    font-size: 1.2rem;

    input {
        width: 100%;
        border: none;
        background-color: transparent;
        outline: none;
        color: inherit;
        font-size: inherit;
        min-width: 200px;

        &::-ms-clear {
            display: none;
        }
    }

    svg {
        position: absolute;
        left: 10px;
        height: 40%;
        color: inherit;
    }

    &.sm {
        font-size: 1rem;
        input {
            padding: 8px 12px;
        }
    }

    &.md {
        input {
            padding: 12px 24px;
        }
    }

    &.lg {
        width: 100%;
        border-radius: 30px;
        margin: 10px 0 20px;

        input {
            padding: 16px 22px;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        font-size: 1rem;
        &.lg {
            border: none;
            width: 90%;
            border-radius: 30px;
            margin: 0;

            input {
                padding: 16px 22px;
            }
        }
        &.sm {
            input {
                padding: 8px 12px;
            }
        }
    }
}