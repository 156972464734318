.container {
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        width: 100%;

    }
}