.container {
    position: relative;
    font-weight: 700;
    justify-content: center;
    align-items: center;

    .dropdown {
        position: absolute;
        color: inherit;
        white-space: nowrap;
        gap: 10px;
        padding-top: 15px;
        align-items: center;
        max-width: unset;

        .options {
            background-color: #fff;
            border-radius: 20px;
            padding: 5px 10px;
            box-shadow: var(--shadow);
            font-weight: 200;
            padding: 10px;
            gap: 10px;
            align-items: center;

            .inputs {
                flex-direction: row;
                direction: ltr;
                gap: 5px;
                font-size: 0.8rem;

                input {
                    text-align: center;
                    padding: 3px 6px;
                    width: 85px;
                    outline: none;
                    border-radius: 20px;
                    border: solid 1px #eee;
                }
            }
            .cancel {
                align-items: center;
                padding: 6px 20px;
                border-radius: 20px;
                font-size: 0.8rem;
                background-color: #2274fa;
                color: #fff;
            }
        }
    }

    .icons {
        font-size: 1.2rem;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        span {
            font-size: 0.7em;
        }
    }

    &:hover, &.active {
        box-shadow: inset 0 2px 5px rgba(0,0,0,0.3);
        .icons {
            svg {
                color: #2274fa;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        .dropdown {
            bottom: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            span {
                font-size: 0.8rem;
                font-weight: 700;
            }
        }
        .title {
            display: none;
        }
        &:not(.active) {
            .icons {
                span {
                    display: none;
                }
            }
        }
    }
}
@media screen and (min-width: 769px) {
    .container {
        .dropdown {
            top: 100%;
            right: 50%;
            transform: translateX(50%);
            span {
                display: none;
            }
        }
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}