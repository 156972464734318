.container {
    &.mobile {
        gap: 20px;
        h3 {
            font-size: 2rem;
            align-self: center;
        }
        nav {
            flex-direction: row;
            gap: 10px;
            padding: 10px;
            flex-wrap: wrap;
        }
        
        a {
            width: calc(50% - 5px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            gap: 10px;
            background: #fff;
            padding: 15px 20px;
            color: var(--color-main);
            background-color: var(--bg-main);

            .icon {
                background-color: #fff;
                border-radius: 50%;
                width: 40px;
                align-items: center;
                justify-content: center;
                aspect-ratio: 1;
            }
            svg {
                font-size: 1.4rem;
            }
        }
        .more {
            background-color: #f8f8f8;
        }
    }
    &.desktop {
        .category {
            gap: 15px;
    
            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-radius: 10px;
                gap: 10px;
                background: var(--bg-main);
                padding: 15px 20px;
                color: var(--color-main);
    
                .icon {
                    background-color: #fff;
                    border-radius: 50%;
                    width: 40px;
                    align-items: center;
                    justify-content: center;
                    aspect-ratio: 1;
                }
                .title {
                    flex: 1;
                    align-items: flex-start;
    
                    a {
                        color: var(--color-main);
                        transform: scale(1, 1.05);
                        letter-spacing: -0.5px;
                        font-weight: 700;
                        margin-top: -5px;
                        font-size: 1.8rem;
    
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
    
                .open {
                    width: 30px;
                }
                
                svg {
                    font-size: 1.2rem;
                }
            }
            
            .nav {
                max-height: 0;
                column-gap: 20px;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                justify-content: flex-start;
                padding: 0 10px;
                overflow: hidden;
    
                a {
                    width: calc(20% - 16px);
                    display: flex;
                    color: var(--color-main);
                    font-weight: 500;
                    position: relative;
                    flex-shrink: 0;
                    align-items: flex-start;
                    white-space: nowrap;
                    margin-bottom: 20px;
            
                    img {
                        width: 100%;
                    }
        
                    span {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        padding: 10px;
                        color: #fff;
                        font-size: 1.2rem;
                        z-index: 1;
                        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
                        display: flex;
                        width: 100%;
                    }
        
                    &:hover {
                        img {
                            box-shadow: var(--shadow);
                        }
                    }
                }
    
                p {
                    width: 100%;
                    font-size: 2rem;
                    font-weight: 700;
                    color: var(--color-main);
                    margin: 20px 0;
                    text-align: center;
                }
    
                .logo {
                    width: 100%;
                    position: relative;
                    color: #fff;
                    font-size: 1.2rem;
                    svg {
                        filter: drop-shadow(0 0 2px rgba(0,0,0,0.5));
                        position: absolute;
                        bottom: 5%;
                        left: 5%;
                    }
                }
            }
            &.active {
                .header {
                    position: sticky;
                    top: 0;
                    z-index: 2;
                }
                nav {
                    max-height: 2000px;
                }
            }        
        }
    }
}