.container {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: var(--max-width);

    &>div {
        width: 100%;
    }
    
    &.size0 {
        width: 100%;
        max-width: var(--max-width);
        min-height: 300px;
        min-height: 90px;
    }
    
    &.dev {
        background-color: #fff;
    }
}

@media screen and (max-width: 768px) {
    .container {
        width: 100%;

        &.size0 {
            width: 100%;
            max-width: unset;
            height: 300px;
            max-height: unset;
        }

        &.size300_600 {
            width: 100%;
            max-height: 500px;
            min-height: 90px;
        }
    }
}
@media screen and (min-width: 769px) {
    .container {
        &.dev {
            border-radius: 20px;
            box-shadow: var(--shadow);
        }
        
        &.size300_600 {
            width: 300px;
            height: 600px;
        }
    }
    
}