.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.container {
    position: relative;
    background-color: var(--bg-main);
    padding: 20px;
    border-radius: 20px;
    gap: 20px;

    .close {
        position: absolute;
        top: -50px;
        left: 0;
        cursor: pointer;
        font-size: 1.5rem;
        background-color: var(--bg-main);
        border-radius: 100%;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        color: var(--text-main);
    }

    &>div {
        flex-direction: row;
        gap: 20px;

        .input {
            gap: 5px;
            font-weight: 700;
            font-size: 0.8rem;
            flex: 1;

            input:not([type=checkbox]), select {
                padding: 5px;
                font-weight: 300;
                border: none;
                border-radius: 5px;
                color: var(--text-main);
                width: 100%;
                text-align: center;
            }
        }
        .checkbox {
            gap: 5px;
            font-size: 0.8rem;
            font-weight: 700;

            div {
                flex-direction: row;
                gap: 10px;

                div {
                    flex-direction: row;
                    gap: 10px;
                    background-color: #fff;
                    padding: 3px 6px;
                    border-radius: 5px;
                    font-weight: 400;
                }
            }
        }
    }
    &.active {
        display: flex;
    }
}
