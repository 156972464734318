.container {
    gap: 20px;
    h4 {
        font-size: 1rem;
        span {
            font-size: 0.7rem;
            font-weight: 300;
        }
    }
    li {
        margin: 10px 0;
        ul {
            li {
                display: flex;
                gap: 5px;
                align-items: center;

                span {
                    i {
                        font-size: 0.9em;
                        font-weight: 300;
                    }
                    &:first-of-type {
                        font-size: 0.8em;
                        font-weight: 500;
                        background-color: #fff;
                        border-radius: 10px;
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 0 20px;
    }
}
@media screen and (min-width: 769px) {
    .container {
        
    }
}