.container {
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 100px;
    max-width: 400px;

    h1 {
        font-size: 3rem;
    }

    input {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        border: solid 1px #ddd;
        border-radius: 5px;
        text-align: center;
    }
    button {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        border: none;
        border-radius: 5px;
        background-color: var(--color-tint);
        color: var(--color-sub);
        font-weight: 700;
        cursor: pointer;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .container {
        
    }
}