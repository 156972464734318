.container {
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;

    h3 {
        font-size: 1.4rem;
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        background: var(--bg-main);
        padding: 5px 10px;
        color: var(--color-main);

        .icon {
            background-color: #fff;
            border-radius: 50%;
            width: 30px;
            align-items: center;
            justify-content: center;
            aspect-ratio: 1;

            svg {
                font-size: 1rem;
            }
        }
        .title {
            flex: 1;
            p {
                font-size: 0.8rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        align-items: flex-start;
        gap: 5px;
        
        .header {
            align-items: center;
            gap: 10px;

            h3 {
                font-size: 1rem;
            }
        }
    }
}