.container {
    position: relative;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    .dropdown {
        position: absolute;
        color: inherit;
        white-space: nowrap;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: var(--shadow);
        align-items: center;
        max-width: unset;

        ul {
            list-style: none;            
            font-weight: 200;
            padding: 10px;
            font-size: 0.8rem;

            li {
                padding: 6px 20px;
                border-radius: 20px;
                text-align: center;
                margin: 2px 0;
    
                &.active {
                    background-color: #0FB7FE;
                    color: #fff;
                }
                &:hover {
                    background-color: #2274fa;
                    color: #fff;
                }
            }
        }
    }

    .icons {
        font-size: 1.2rem;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        height: 100%;
        justify-content: center;
        align-items: center;

        span {
            font-size: 0.7em;
        }
    }
    &:hover, &.active {
        box-shadow: inset 0 2px 5px rgba(0,0,0,0.3);
        .icons {
            svg {
                color: #2274fa;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        .dropdown {
            bottom: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            span {
                font-size: 0.8rem;
                font-weight: 700;
            }
        }
        .title {
            display: none;
        }
        &:not(.active) {
            .icons {
                span {
                    display: none;
                }
            }
        }
    }
}
@media screen and (min-width: 769px) {
    .container {
        .dropdown {
            top: 100%;
            right: 50%;
            transform: translateX(50%);
            span {
                display: none;
            }
        }
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}