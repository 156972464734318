.container {
    gap: 10px;
    width: 100%;

    h3 {
        font-size: 2rem;
    }
    nav {
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
    
        a {
            width: calc(10% - 7px);
            display: flex;
            flex-direction: column;
            color: var(--color-main);
            gap: 5px;
            font-weight: 500;
        }

        .logo {
            position: relative;
            width: 100%;
            color: #fff;
            svg {
                filter: drop-shadow(0 0 2px rgba(0,0,0,0.5));
                position: absolute;
                bottom: 5%;
                right: 5%;
            }
        }
    
        &.column {
            flex-wrap: wrap;
        }
    
        &.row {
            
        }
    }
    
}

@media screen and (max-width: 768px) {
    .container {

        nav {
            a {
                flex-shrink: 0;
                font-size: 0.9rem;
                align-items: flex-start;
                white-space: nowrap;
                scroll-snap-align: center;
                gap: 2px;
    
                span {
                    margin-inline-start: 5px;
                }
            }
            &.sm {
                a {
                    width: 4vw;
                }
            }
            &.md {
                a {
                    width: calc(100% / 4);
                }
            }
            &.lg {
                a {
                    width: 35%;
                }
            }
            &.row:not(.sm) {
                overflow-x: scroll;
                flex-wrap: nowrap;
                justify-content: flex-start;
                scroll-snap-type: x mandatory;
                    &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}
@media screen and (min-width: 769px) {
    .container {

        nav {
            a {
                &:hover {
                    img {
                        transform: rotateZ(5deg) translateY(-5px);
                    }
                }
            }
            &.sm {
                a {
                    height: 30px;
                    width: 47px;

                    .logo, img {
                        height: 100%;
                    }
                }
            }
            &.md {
                a {
                    width: 10%;

                    .logo {
                        width: 100%;
                    }
                }
            }
            &.lg {
                flex-wrap: wrap;
                a {
                    width: calc(20% - 10px);

                    .logo {
                        width: 100%;
                    }
                }
            }
        }
    }
}