.container {
    img {
        width: 50px;
        border-radius: 10px;
        border: solid 3px #fff;
    }
    .go {
        position: absolute;
        display: none;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: center;
        top: 50%;
        width: 100%;
        background-color: #12B168;
        border-radius: 20px;
        padding: 7px 14px;
        cursor: pointer;
        transition: all 0.3s;
        color: #fff;
    }
    .artists {
        flex-direction: row;
        gap: 5px;

        span {
            font-size: 0.8em;
            font-weight: 500;
            background-color: #fff;
            border-radius: 10px;
            padding: 5px 10px;
        }
    }
    &.mobile {
        .track {
            flex-direction: row;
            align-items: center;
            gap: 10px;
            color: #000;
            position: relative;
            font-size: 0.9rem;
            padding: 7px 14px;
            white-space: nowrap;
            border-bottom: 1px solid rgba(0,0,0,0.05);

            &>div {
                gap: 5px;
                flex: 1;
            }

            .go {
                left: 10px;
                transform: translate(0, -50%);
                box-shadow: var(--shadow);
                width: unset;
            }

            &:hover {
                background-color: rgba(255,255,255,0.4);
                .go {
                    display: flex;
                }
            }
            &:last-of-type {
                border-bottom: none;
            }
        }
        .artists {
            flex-wrap: wrap;
        }
        .width {
            font-weight: 700;

            &>span {
                padding: 0 0 0 10px;
                white-space: break-spaces;
            }
        }
    }
    &.desktop {
        gap: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        border-collapse: collapse;
        table-layout: auto;
    
        th, td {
            text-align: center;
            font-size: 0.9rem;
            padding: 7px 14px;
            white-space: nowrap;
            position: relative;
    
            &.right {
                text-align: right;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    
        th {
            color: #000;
        }
    
        tr {
            border-bottom: 1px solid rgba(0,0,0,0.05);
            &:hover {
                td {
                    background-color: rgba(255,255,255,0.4);
                }
            }
            &:last-of-type {
                border-bottom: none;
            }
        }
        .width {
            gap: 5px;
            align-items: flex-start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;

            span {
                font-weight: 300;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 300px;
            }
        }
        .track {
            flex-direction: row;
            align-items: center;
            gap: 10px;
            color: #000;

            .width {
                &>span {
                    font-weight: 700;
                }
            }
        }
        .duration {
            flex-direction: column;
            font-size: 0.9em;
            span {
                &:first-of-type {
                    color: #000;
                    font-weight: 700;
                }
                &:last-of-type {
                    font-weight: 300;
                }
            }
        }
        tr {
            &:hover {
                .go {
                    display: flex;
                    font-size: 0.9em;
                }
            }
        }
        .go {
            right: 0;
            transform: translate(0, -50%);
        }
        .artists {
            flex-wrap: wrap;
        }
    }
    
}