.container {
    font-size: 0.9rem;
    max-width: 100%;
    padding-bottom: 60px;

    section {
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;

        p {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            text-align: center;
            align-items: center;
        }

        .content {
            flex-direction: row;
            align-items: center;
            gap: 50px;

            .logo {
                width: 15%;
                transform: rotateZ(30deg);
            }

            nav {
                gap: 10px;
                h4 {
                    margin-bottom: 10px;
                }
                a {
                    color: inherit;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .acum {
            align-items: center;
            justify-content: center;
            display: flex;
            background-color: var(--color-main);
            padding: 3px 5px;
            border-radius: 5px;
            position: relative;

            img {
                width: 50px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        section {
            padding: 20px 10px;

            .content {
                align-items: center;
                justify-content: space-between;
                nav {
                    align-items: center;
                }
            }
        }
    }
}
@media screen and (min-width: 769px) {
    .container {
        section {
            .content {
                flex-direction: row;
            }
        }
    }
}