.container {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 0.8rem;
    
    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 20px;
        background-color: #fff;
        color: var(--color-main);

        span {
            span {
                font-size: 0.7rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        margin-top: 10px;
    }
}
@media screen and (min-width: 769px) {
    .container {
        .a {
            &:hover {
                background-color: var(--color-tint);
                color: var(--color-sub);
            }
        }
    }
    
}